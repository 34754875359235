import React from 'react'
import '../css/navbar.css'
import '../css/spacing.css'
import haroLogo from '../img/text-logo-green.svg'
import initiaLogo from '../img/INITIA_2019_Logo_Grey.png'

const Navbar = () => {
  return (
    <div class="navbar section-width">
        <img src={haroLogo} alt="harrold-logo" class="harrold-logo"/>
        <a href="https://harrold.buyyvr.com/">
          <img src={initiaLogo} class="initia-logo" alt="initia-logo"/>
        </a>
    </div>
  )
}

export default Navbar