import React, { useState, useRef, useEffect } from 'react'
import emailjs from '@emailjs/browser';
import '../css/footer.css'
import '../css/color.css'
import '../css/spacing.css'
import initiaLogo from '../img/INITIA_2019_Logo_White.png'

const Footer = ({ shouldFocusForm, setShouldFocusForm }) => {
    // Initialize formData variable
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: '',
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    // Send Email on button click
    const formRef = useRef();
    const handleSubmit = (event) => {
        event.preventDefault();

        emailjs
            .sendForm('service_x59c5mq', 'template_a6kl3cq', formRef.current, {
                publicKey: 'Dgc5x8z9MOMYBTRs9',
            })
            .then(
                () => {
                    alert("Thanks for reaching out! Your message has been sent and I'll be in touch shortly. Your patience is much appreciated.");
                    setFormData({
                        name: '',
                        email: '',
                        phone: '',
                        message: '',
                    });
                },
                (error) => {
                    alert("Error sending message. Sorry for the inconvenience.");
                    setFormData({
                        name: '',
                        email: '',
                        phone: '',
                        message: '',
                    });
                    console.log('FAILED...', error.text);
                },
            );
    };

    // Handle focusing on form
    const nameFieldRef = useRef();
    useEffect(() => {
        if (shouldFocusForm) 
        {
            nameFieldRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
            setTimeout(() => {
                nameFieldRef.current.focus();
            }, 500);
            setShouldFocusForm(false);
        }
    }, [shouldFocusForm]);

    return (
        <div class="footer-bg bg-green padding-auto">
            <div class="footer section-width">
                <div class="footer-title">
                    <span class="f-head f3 c-white">Let's Work Together</span>
                </div>
                <div class="fl foo">
                    <div class="fl2 footer-spacer" />
                    <div class="footer-body fl10">
                        <div class="contact-info fl3 fl-col">
                            <span class="f-head f6 c-white">Cell: 604-670-4137</span>
                            <span class="f-head f6 c-white">Tel:  604-761-3438</span>
                            <span class="f-head f6 c-white">Office:</span>
                            <div class="office-details">
                                <a href="https://harrold.buyyvr.com/" class="initia-logo">
                                    <img src={initiaLogo} alt="initia-logo" class="initia-logo-img"/>
                                </a>
                                <span class="f-head f6 c-white">52 Powell St. #200</span>
                                <span class="f-head f6 c-white">Vancouver, BC</span>
                                <span class="f-head f6 c-white">V6A 1E7</span>
                            </div>
                            <a class="f-head f6 c-gold initia-link" href="https://harrold.buyyvr.com/">harrold.BuyYVR.com</a>
                        </div>
                        <div class="divider fl1"><div class="divider-line" /></div>
                        <div class="message-form fl8">
                            <form ref={formRef} onSubmit={handleSubmit}>
                                <label class="f-body f8 w100" for="name">Name:</label><br />
                                <input class="f-body f6 w100" 
                                    type="text" 
                                    id="name" 
                                    name="name"
                                    ref={nameFieldRef}
                                    value={formData.name}
                                    onChange={handleChange}
                                    required
                                /><br />
                                <div class="fl lead-contact">
                                    <div class="fl6">
                                        <label class="f-body f8 w100" for="email">Email:</label><br />
                                        <input class="f-body f6 w100" 
                                            type="text" 
                                            id="email" 
                                            name="email"
                                            value={formData.email}
                                            onChange={handleChange}
                                            required 
                                        /><br />
                                    </div>
                                    <div class="fl6">
                                        <label class="f-body f8 w100" for="phone">Phone:</label><br />
                                        <input class="f-body f6 w100" 
                                            type="text" 
                                            id="phone" 
                                            name="phone" 
                                            value={formData.phone}
                                            onChange={handleChange}
                                        /><br />
                                    </div>
                                </div>
                                <label class="f-body f8 w100" for="message">Message:</label><br />
                                <textarea class="f-body f6 w100" style={{resize:'none'}} 
                                    id="message" 
                                    name="message"
                                    value={formData.message}
                                    onChange={handleChange}
                                    rows={4}
                                    required 
                                /><br />
                                <button class="submit-button f-head f7 bg-gold" type="submit">Submit</button>
                            </form> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer