import React from 'react'
import '../css/testimonials.css'
import '../css/spacing.css'

const Testimonials = () => {
    const testimonialBody = 'As a first time homebuyer, I was unfamiliar with the process of purchasing a home. Harrold was very informative and knowledgeable with each step and helped me find a property that I love. He provided great feedback and was very responsive whenever I had questions. He took time to introduce me to properties and even highlighted each property’s key features. He kept my interest in focus while providing me with multiple options. It was a pleasure working with Harrold. He had great energy at every interaction and I greatly appreciate him and his efforts. Highly Recommend!!';

  return (
    <div class="testimonials section-width">
        <div class="testimonial-card">
            {/* <div class='stars'></div> */}
            <p class="testimonial-body f-body f7"> {testimonialBody} </p>
            <p class="testimonial-author f-head f6">- Kareena P.</p>
        </div>
    </div>
  )
}

export default Testimonials