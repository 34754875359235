import { useState, useRef } from 'react'
import React from 'react'
import Navbar from './Navbar.js'
import Hero from './Hero.js'
import Value from './Value.js'
import Testimonials from './Testimonials.js'
import Footer from './Footer.js'

const Main = () => {
    const [shouldFocusForm, setShouldFocusForm] = useState(false);

    // Handle focus on form when button clicked
    const onClickFocusForm = () => {
        setShouldFocusForm(true);
    };

    return (
        <div>
            <Navbar />
            <Hero onClickFocusForm={ onClickFocusForm } />
            <Value />
            <Testimonials />
            <Footer shouldFocusForm={ shouldFocusForm } setShouldFocusForm={ setShouldFocusForm }/>
        </div>
    )
}

export default Main