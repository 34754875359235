import React from 'react'
import '../css/hero.css'
import '../css/color.css'
import '../css/spacing.css'
import harroldImage from '../img/harrold.jpg'

const Hero = ({ onClickFocusForm }) => {
    const heroBody = 'I\'m Harrold, proud to be a part of the dynamic real estate scene with Initia Real Estate in Vancouver. As your trusted guide, my mission is to Simplify and Streamline your real estate journey, whether you\'re a first-time buyer or a seasoned investor. No complex jargon—just clear, professional guidance with a personal touch. Let\'s work together to make your real estate adventure both successful and stress-free. I\'m excited to assist you in achieving your goals.';

    return (
        <div class="hero section-width">
            <div class="about">
                <span class="f-head f2">
                    Confidence in Every Step
                </span>
                <span class="f-head f3">
                    Your Real Estate Guide
                </span>
                <span class="f-body f6 hero-body">
                    {heroBody}
                </span>
                <div class="hero-buttons">
                    <a href="https://harrold.buyyvr.com/">
                        <button id="search-button" class="f-head f7">Search Properties</button>
                    </a>
                    <button id="message-button" class="f-head f7 bg-green" onClick={ onClickFocusForm }>
                        Get in Touch</button>
                </div>
            </div>
            <div class="hero-img-container">
                <img class="hero-img w100" src={harroldImage} alt="harrold-portrait"/>
            </div>
        </div>
    )
}

export default Hero