import React from 'react'
import '../css/value.css'
import '../css/color.css'
import '../css/spacing.css'
import iconSearch from '../img/home-search-green.png'
import iconContracts from '../img/contracts-green.png'
import iconNegotiate from '../img/shaking-hands-green.png'

const Value = () => {
    return (
        <div class="value">
            <div class='gold-bg bg-gold'/>
            <div class='value-body bg-green'>
                <div class="value-cards-container section-width">
                    <div class="value-card">
                        <div class="circle">
                            <img src={iconSearch} alt="icon-search" />
                        </div>
                        <span class="f-body f5 c-white">Finding the best homes for you</span>
                    </div>
                    <div class="value-card">
                        <div class="circle">
                            <img src={iconContracts} alt="icon-contracts" />
                        </div>
                        <span class="f-body f5 c-white">Guiding you through your transaction</span>
                    </div>
                    <div class="value-card">
                        <div class="circle">
                            <img src={iconNegotiate} alt="icon-negotiate" />
                        </div>
                        <span class="f-body f5 c-white">Negotiating to secure your best interest</span>
                    </div>
                </div>
            </div>
            <div class='gold-bg bg-gold'/>
        </div >
    )
}

export default Value